export const overwriteInitialValues = (initialValues, otherObject = {}) =>
	// create a clone of initialValues and overwrite each field of it
	// with corresponding value from otherObject, if that exists

	Object.entries(initialValues).reduce(
		(result, [key, value]) => ({
			...result,
			[key]: otherObject[key] || value,
		}),
		{}
	);

// because I find
// 		label={withAsterisk(label.vorname)}
// 	better readable than
// 		label={`${label.vorname} *`}
export const withAsterisk = (str) => `${str} *`;
