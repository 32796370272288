import {overwriteStandard} from './helper';

// ## standard values for forms ##

/**
 * standard values that are used in more than one form
 *
 * usage:	overwriteStandard(formsStandard, {
 * 					value1: ...,
 * 					sub: {
 * 						value2: ...
 * 					}
 * 				})
 */
const formsStandard = {
	label: {
		cellPhone: 'Mobiltelefon',
		city: 'Ort',
		company: 'Firma',
		country: 'Land',
		dateOfBirth: 'Geboren am (TT.MM.JJJJ)',
		email: 'E-Mail-Adresse',
		fax: 'Fax',
		federalState: 'Bundesland',
		forename: 'Vorname',
		houseNumber: 'Hausnummer/Zusatz',
		message: 'Deine Nachricht',
		occupation: 'Beruf',
		password: 'Passwort',
		passwordConfirm: 'Passwort (Wiederholung)',
		newPassword: 'Neues Passwort',
		newPasswordConfirm: 'Neues Passwort (Wiederholung)',
		phone: 'Telefonnummer',
		salutation: 'Anrede',
		medication: 'Mein vom Arzt verordnetes MS-Medikament',
		street: 'Straße / Hausnummer',
		streetHouseNumber: 'Straße / Hausnummer',
		subject: 'Betreff',
		surname: 'Nachname',
		title: 'Titel',
		userName: 'Benutzername',
		zip: 'PLZ',
		send: 'Abschicken',
	},

	validationMessages: {
		captcha: 'Dieses Feld muss bestätigt werden.',
		cellPhone: {
			ifTooShort: 'Die Mobilnummer ist zu kurz.',
			ifTooLong: 'Die Mobilnummer ist zu lang.',
		},
		city: {
			ifEmpty: 'Bitte gib eine Stadt ein.',
			ifTooShort: 'Der Stadtname ist zu kurz.',
			ifTooLong: 'Der Stadtname ist zu lang.',
		},
		country: 'Bitte gib einen Ländernamen ein.',
		email: {
			ifEmpty: 'Bitte gib eine E-Mail-Adresse ein.',
			ifNotValid: 'Das ist keine gültige E-Mail-Adresse.',
		},
		fax: {
			ifTooShort: 'Die Faxnummer ist zu kurz.',
			ifTooLong: 'Die Faxnummer ist zu lang.',
		},
		federalState: 'Dieses Bundesland existiert nicht.',
		forename: {
			ifEmpty: 'Bitte gib einen Vornamen ein.',
			ifTooShort: 'Bitte gib einen Vornamen ein.',
		},
		legal_hint: 'Der Hinweis muss bestätigt werden.',
		message: 'Bitte gib eine Nachricht ein.',
		medication: {
			ifEmpty: 'Bitte wähle ein verodnetes MS-Medikament aus.',
		},
		password: {
			ifEmpty: 'Bitte gib ein Passwort ein.',
			ifTooShort: 'Das Passwort ist zu kurz.',
			ifTooLong: 'Das Passwort ist zu lang.',
			ifFalseFormat:
				'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Sonderzeichen, 1 Großbuchstaben, 1 Zahl und 1 Kleinbuchstaben enthalten.',
		},
		passwordConfirm: {
			ifEmpty: 'Bitte gib ein Passwort ein.',
			ifNoMatch: 'Die Passwörter stimmen nicht überein.',
		},
		phone: {
			ifEmpty: 'Bitte gib eine Telefonnummer ein.',
			ifTooShort: 'Die Telefonnummer ist zu kurz.',
			ifTooLong: 'Die Telefonnummer ist zu lang.',
		},
		salutation: {
			ifEmpty: 'Bitte gib deine Anrede ein.',
		},
		streetHouseNumber: {
			ifEmpty: 'Bitte gib deine Straße und Hausnummer ein.',
			ifTooShort: 'Sind Straße und Hausnummer vollständig?',
			ifTooLong: 'Straßenname und Hausnummer sind zu lang.',
		},
		subject: 'Bitte gib einen Betreff ein.',
		surname: {
			ifEmpty: 'Bitte gib einen Nachnamen ein.',
			ifTooShort: 'Bitte gib einen Nachnamen ein.',
		},
		userName: {
			ifEmpty: 'Bitte gib einen Benutzernamen ein.',
			ifTooShort: 'Der Benutzername ist zu kurz.',
			ifTooLong: 'Der Benutzername ist zu lang.',
		},
		zip: {
			ifEmpty: 'Bitte gib deine Postleizahl ein.',
			ifTooShort: 'Die Postleitzahl ist zu kurz.',
			ifTooLong: 'Die Postleitzahl ist zu lang.',
		},
	},
};

// ## export values for forms ##

export const forms = {
	AutoEnrollerForm: overwriteStandard(formsStandard, {
		label: {
			contactTimeStart: 'Am besten bin Ich erreichbar von',
			contactTimeEnd: 'Am besten bin Ich erreichbar bis',
			phone: 'Telefon- / Mobilnummer',
			firstnameDoc: 'Vorname',
			lastnameDoc: 'Nachname',
			streetDoc: 'Straße / Hausnummer',
			zipDoc: 'PLZ',
			dob: 'Geburtstag',
			cityDoc: 'Stadt',
			medication: 'Mein verordnetes Biogen MS-Medikament',
			info: 'Sonstige Bemerkungen:',
			messageBox: 'Sollte ich nicht erreichbar sein, dürfen Sie mir gerne eine Nachricht hinterlassen.',
			registerInfo:
				'Ja, ich möchte am MS-Begleitprogramm teilnehmen und bin mit der ' +
				'<a href="/gemeinsam-stark-datenschutzrichtlinie">Datenschutzerklärung</a> einverstanden. Ich willige ein in die Verarbeitung ' +
				'meiner mit dieser Einwilligungserklärung mitgeteilten Daten und der zur Teilnahme ' +
				'am MS-Begleitprogramm benötigten Daten gemäß dem in der ' +
				'Datenschutzrichtlinie dargestellten Umfang durch Biogen. Zu den Daten zählen auch ' +
				'besondere Kategorien personenbezogener Daten, die einen Bezug zu meiner Multiplen ' +
				'Sklerose und meiner Behandlung haben.',
			therapyStartDate: 'Datum Therapiestart',
		},

		validationMessages: {
			email: {
				ifEmpty: 'Bitte gib Deine E-Mail-Adresse ein.',
				ifNotValid: 'Bitte gib Deine gültige E-Mail-Adresse ein.',
			},
			forename: {
				ifEmpty: 'Bitte gib Deinen Vornamen ein.',
			},

			registerInfo: 'Bitte bestätige die Teilnahme am Programm.',
			dataProtection: 'Bitte bestätige Dein Einverständnis mit dem Datenschutz.',

			medication: {
				ifEmpty: 'Bitte gebe Deine verordnete Medikation an.',
				ifSelect: 'Bitte wähle Deine verordnete Medikation aus.',
			},
			phones: {
				ifEmpty: 'Bitte gebe deine Telefon und oder Mobilnummer an.',
			},
			dob: 'Bitte gebe Deinen Geburtstag an.',
			firstnameDoc: {ifEmpty: 'Bitte gebe den Vornamen Deines Arztes ein'},
			lastnameDoc: {ifEmpty: 'Bitte gebe den Nachnamen Deines Arztes ein'},
			houseNumber: {ifEmpty: 'Bitte gebe Deine Hausnummer und ggf. Zusatz ein.'},
			phone: {ifEmpty: 'Bitte gebe deine Telefonnummer ein.'},
			salutation: 'Bitte wähle Deine Anrede aus.',
			street: {ifEmpty: 'Bitte gebe Deinen Straßennamen ein.'},
			streetDoc: {ifEmpty: 'Bitte gebe den Straßennamen Deines Arztes ein.'},
			surname: {
				ifEmpty: 'Bitte gebe Deinen Nachnamen ein.',
			},
			city: {ifEmail: 'Bitte gebe Deinen Stadtnamen ein.'},
			cityDoc: {ifEmpty: 'Bitte gebe den Stadtnamen Deines Arztes ein.'},
			zip: {ifEmpty: 'Bitte gebe Deine Postleitzahl ein.'},
			zipDoc: {ifEmpty: 'Bitte gebe die Postleitzahl Deines Arztes ein.'},
			therapyStartDate: 'Bitte gebe Dein Therapiestartdatum an.',
		},
	}),
	BrochureOrder: overwriteStandard(formsStandard, {
		label: {
			hint1Accepted:
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. *',
			hint2Accepted:
				'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. *',
		},

		validationMessages: {
			country: 'Bitte wähle ein Land aus.',
			email: {
				ifEmpty: 'Bitte gib eine E-Mail-Adresse ein.',
				ifNotValid: 'Bitte gib eine gültige E-Mail-Adresse ein.',
			},
			federalState: 'Bitte wähle ein Bundesland aus.',
			forename: {
				ifEmpty: 'Bitte gib einen Vornamen ein.',
			},

			hint1Accepted: 'Der Hinweis muss bestätigt werden.',
			hint2Accepted: 'Der Hinweis muss bestätigt werden.',

			houseNumber: 'Bitte gib eine Hausnummer und ggf. Zusatz ein.',
			phone: 'Bitte gib eine Telefonnummer ein.',
			salutation: 'Bitte wähle eine Anrede aus.',
			street: 'Bitte gib einen Straßennamen ein.',
			surname: {
				ifEmpty: 'Bitte gib einen Nachnamen ein.',
			},
		},
	}),
	CallbackService: overwriteStandard(formsStandard, {
		initialValuesPreset: {
			subject: 'Bitte rufen Sie mich an!',
		},
		label: {
			legal_hint:
				'Wir möchten dich darauf hinweisen, dass wir keine ' +
				'individuellen Diagnosen stellen oder Therapievorschläge ' +
				'geben und empfehlen dir grundsätzlich, deinen Arzt für ' +
				'Fragen zum individuellen Krankheitsbild aufzusuchen. ' +
				'Wir erheben höchsten Anspruch an die bereitgestellten ' +
				'Informationen, gleichwohl wir keine Haftung im Einzelfall übernehmen.',
		},
		validationMessages: {},
	}),
	Contact: overwriteStandard(formsStandard, {
		label: {
			privacy_policy: 'Ich bin mit der Datenschutzerklärung einverstanden.',
		},
		validationMessages: {
			email: {
				ifEmpty: 'Bitte gib eine E-Mail-Adresse ein.',
			},
			forename: {
				ifEmpty: 'Bitte gib einen Vornamen ein.',
			},
			message: 'Bitte gib eine Nachricht ein.',
			privacy_policy: 'Die Datenschutzerklärung muss bestätigt werden.',
			surname: {
				ifEmpty: 'Bitte gib einen Nachnamen ein.',
			},
		},
	}),
	Register: overwriteStandard(formsStandard, {
		text: {
			intro:
				'Deine Zugangsdaten werden dir umgehend per Mail zugesandt. ' +
				'Sie können jederzeit online geändert werden.',
			mandatory: 'Die mit einem * gekennzeichneten Felder sind Pflichtfelder. ',
			infobox:
				'<h3>Hinweis</h3><p>Bitte beachte, dass von dir verfasste ' +
				'Beiträge unter dem von dir gewählten Benutzernamen in den Foren ' +
				'erscheinen und von Suchmaschinen gefunden werden können.</p>',
			hint1:
				'Zum Schutz deiner Daten erhältst du umgehend eine ' +
				'Registrierungsbestätigung per E-Mail. Diese enthält ' +
				'einen Bestätigungslink, den du lediglich noch ' +
				'anklicken musst, um deine Registrierung abzuschließen. ' +
				'Zusätzlich enthält die Registrierungsbestätigung deine ' +
				'Zugangsdaten. Du kannst diese jederzeit online über den ' +
				'Menüpunkt "Meine Daten" ändern.',
			hint2:
				'Du hast weiterhin die Möglichkeit, individuelle, ' +
				'auf deine ganz persönlichen Bedürfnisse zugeschnittene ' +
				'Informationen zu erhalten. Bitte fülle hierzu die ' +
				'Einwilligungserklärung zur Erhebung sensitiver Daten ' +
				'(die einen Bezug zur Krankheit haben) aus, die der ' +
				'Registrierungsbestätigung angehängt ist. Die ' +
				'unterschriebene vervollständigte Einwilligungserklärung ' +
				'sendest du bitte per Post oder Fax an uns zurück.',
		},
		label: {
			email: 'E-Mail',
			phone: 'Telefon',
			recommendation: 'Wie hast du von uns erfahren?',
			media: 'Wie hast du vom MS Service-Center erfahren? *',
			moreInfo: 'Hier kannst du nähere Angaben machen',
			importantHint: 'Wichtiger Hinweis',
			salutation: 'Anrede',
			newsletter:
				'Durch Aktivieren dieses Kästchens erklärst du dich damit einverstanden, dass Biogen dir – wie im <a href="https://www.biogen.de/datenschutzrichtlinien.html" target="_blank">Datenschutzhinweis</a> vermerkt – E-Mails mit Informationen über die MS und andere Materialien zusendet, die für dich von Interesse sein könnten.',
			agreement:
				'Durch Aktivieren dieses Kästchens bestätigst du Folgendes: Ich habe die Informationen des <a href="https://www.biogen.de/datenschutzrichtlinien.html" target="_blank">Datenschutzhinweises</a> gelesen und verstanden und willige in die Erhebung, Speicherung und Nutzung meiner personenbezogenen Daten, wie in diesem Datenschutzhinweis beschrieben, ein.',
		},
		validationMessages: {
			forename: {
				ifEmpty: 'Bitte gib deinen Vornamen ein.',
				ifTooShort: 'Bitte gib deinen Vornamen ein.',
			},
			surname: {
				ifEmpty: 'Bitte gib deinen Nachnamen ein.',
				ifTooShort: 'Bitte gib deinen Nachnamen ein.',
			},
			recommendation: 'Bitte wählen sie eine Anrede',
			agreement: 'Stimme zu, damit wir dich registrieren können.',
		},
	}),
	PasswordReset: overwriteStandard(formsStandard, {
		text: {
			headline: 'Passwort zurücksetzen',
			intro: 'Gib dein neues Passwort ein.',
		},
		label: {
			newPassword: 'Neues Passwort',
			repeatPassword: 'Passwort wiederholen',
		},
		validationMessages: {
			password: {
				ifTooShort: 'Das Passwort ist zu kurz.',
				ifTooLong: 'Das Passwort ist zu lang.',
				ifEmpty: 'Bitte gib ein Passwort ein.',
				ifFalseFormat:
					'Das Passwort muss mindestens 8 Zeichen lang sein und 1 Sonderzeichen, 1 Großbuchstaben, 1 Zahl und 1 Kleinbuchstaben enthalten.',
			},
			password2: {
				ifNotEqual: 'Die Passwörter stimmen nicht überein.',
				ifEmpty: 'Bitte gib ein Passwort ein.',
			},
		},
		buttons: {
			submit: 'Passwort bestätigen',
		},
	}),
	PasswordResetRequest: overwriteStandard(formsStandard, {
		text: {
			headline: 'Neues Passwort beantragen',
			intro: 'Gib deinen Benutzernamen und deine E-Mail-Adresse ein um ein neues Passwort zu bekommen.',
		},
		label: {
			username: 'Benutzername',
			email: 'E-Mail-Adresse',
		},
		validationMessages: {
			username: {
				ifEmpty: 'Bitte gib deinen Benutzernamen ein.',
			},
			email: {
				ifEmailNotValid: 'Bitte gib eine gültige E-Mail-Adresse ein.',
				ifEmpty: 'Bitte gib deine E-Mail-Adresse ein.',
			},
		},
		buttons: {
			submit: 'Passwort zurücksetzen',
		},
	}),
	TherapyInformation: overwriteStandard(formsStandard, {
		initialValuesPreset: {
			pzn: 'PZN eingeben',
		},
		buttons: {
			submit: 'Verifizieren',
		},
	}),
};

export const content = {
	relatedContent: {
		headline: 'Weitere interessante Inhalte',
	},
};

export const mailRegExp = new RegExp('^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$');
